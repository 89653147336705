<template>
    <!-- ContentImage-->
    <div :class="'lg:w-full lg:mb-0 mb-6 p-4 min-h-15 '">
        <template v-if="(content.data.layout==='rounded')">
            <div class="h-full text-center text-left rounded border shadow-lg  leading-10">
                <img class="w-full object-cover object-center rounded-t inline-block"
                     :src="asset(content.data.file)" :alt="content.data.name">
                {{ content.data.name }}
                <p class="text-gray-500">{{ content.data.title }}</p>
            </div>
        </template>
        <template v-if="(content.data.layout!=='rounded')">
            <div class="h-full text-center text-left   leading-10">
                <img :class="(content.data.css!=='' ? content.data.css+' object-center inline-block' : 'w-full object-cover object-center inline-block')"
                     :src="asset(content.data.file)" :alt="content.data.name">
                <div class="clear-both"></div>
                {{ content.data.name }}
                <p class="text-gray-500 ">{{ content.data.title }}</p>
            </div>
        </template>
    </div>

</template>

<script>

export default {
    emits: ["clicked"], //
    name: "ContentImage",
    components: {

    },
    props: {
        content: Object,
        contents: Object,
        editable:Boolean
    },
    data() {
        return {

        }
    },
    methods: {

    },
}
</script>
